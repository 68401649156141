<template>
  <div id="pageInconnue">

      <bloc-header2 page="Page inconnue" />

    <div id="container-pageInconnue" class="containerAffichage">

        <div class="pageInconnue">
            <img src="../assets/img/page-inconnue.png" alt="page inconnue" />
        </div>
        <div id="mOrienter">
            <div class="sousTitre">Vous cherchez une page qui n'existe pas</div>
            <div class="interaction">
                <img src="../assets/img/fleche.png" alt="fleche" />
                <span>Pour m'orienter</span>
                <a href="/" target="_self" class="bouton majuscules" onclick="event.stopPropagation(); return(0);">
                    <span>Retour à l'accueil</span>
                </a>
            </div>
        </div>
    </div>

    <bloc-footer/>
  </div>
</template>


<script>
import Header2 from '@/components/Header2.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'MentionsLegales',
    data() {
        return {
            tmp:true,
        }
    },
    components: {
        'bloc-header2': Header2,
        'bloc-footer': Footer,
    },
    beforeCreate() {
        this.$parent.sitePRO = true;
    },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    #pageInconnue {
        text-align: center;
        img {
            max-width: 100%;
            height:auto;
            margin:0 auto;
        }

        #container-pageInconnue  {
            min-height: 460px;
            position:relative;
            margin: 0 auto;
            width: 90%;
            max-width: 550px;
        }
    }




#mOrienter {
    position:absolute;
    bottom: 100px;
    right: 10px;
    font-weight:bold;
    text-align:right;
    font-size:85%;

    a {
        text-align:center;
        position:relative;
        z-index: 2;

        &.bouton {
            margin-top:0.5em;
            text-transform: uppercase;
            display: block;
            border: 1px solid #356A73;
            background-color: #356A73;
            color: rgba(255, 255, 255, 0.8);
            padding: 1.2em 2em;
            font-size: 1em;
            font-weight: 600;
            max-width: 150px;
            &:hover, &:focus {
                background-color: white;
                color: #356A73;
            }
        }

    }

    div.sousTitre {
        padding:0 0 1em ;
    }
    div.interaction{
        padding: 0 0 0 2em;
        text-align: left;
    }
}

</style>
